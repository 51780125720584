/* -------------------------------------------------------------------------- */
/*                               Browser Fixing                               */
/* -------------------------------------------------------------------------- */

// Firefox fixed
.firefox{ .dropcap:first-letter{ margin-top: 0.175em; } }

.windows.chrome{
  .btn-close{
    background-clip: unset;
  }
}